/**
 * This file contains style-related methods and variables that works without any DOM nad Vue context.
 * Every current state have to be provided on function call.
 * E.g. currently selected features or current map zoom.
 * This is because it has been adapted to work in a web worker environment.
 */

import { Style, Fill, Stroke, Circle, Icon, Text } from 'ol/style';
import FillPattern from 'ol-ext/style/FillPattern';
import { GeoJSON } from 'ol/format';
import { asArray, asString } from 'ol/color';
import { Point } from 'ol/geom';
import { memoizer, getQueryValue } from '@/assets/js/utility';
import { defaultEpsg } from '@/assets/js/variables';

const erroredColor = '#D40000';
const highlitedColor = '#FF0000';
const selectedColor = '#FFF503';
const haloColorHighlited = [255, 255, 255, 0.5];
const haloColorSelected = [255, 255, 0, 0.5];

const typeStyleFunctionsDist = {
  Polygon: 'getAreaMeasurementStyles',
  MultiPolygon: 'getAreaMeasurementStyles',
  LineString: 'getLengthMeasurementStyles',
  MultiLineString: 'getLengthMeasurementStyles',
  Circle: 'getCircleMeasurementStyles',
  Intersect1: 'getIntersectMeasurementStyles1',
  Intersect2: 'getIntersectMeasurementStyles2',
};

const isFeatureIncludesInCacheFilter = (id, layer, cacheFilterFeatures = {}) => {
  if (!cacheFilterFeatures[layer]) {
    return true;
  }
  return cacheFilterFeatures[layer].includes(id);
};
const isFeatureHighlitedOrSelected = (
  id,
  layer,
  highlitedFeatures = {},
  selectedFeatures = {},
  erroredFeatures = {}
) => {
  if ((highlitedFeatures[layer] || []).includes(id)) {
    return 'highlited';
  } else if ((selectedFeatures[layer] || []).includes(id)) {
    return 'selected';
  } else if ((erroredFeatures[layer] || []).includes(id)) {
    return 'errored';
  }
  return false;
};
const getFeatureMeasureGeometry = (id, layer, measuredFeatures = {}) => {
  return measuredFeatures[layer]?.[id]?.geometry || false;
};
// returns halo effect for a highleted/selected feature label
const getHaloLabelDefinition = (labelsConfig, color) => {
  const strokeColor = color === highlitedColor ? haloColorHighlited : '#303030';
  return {
    ...labelsConfig,
    'font-size': labelsConfig['font-size'] * 1.1,
    'font-color': color,
    'stroke-color': strokeColor,
    'stroke-visible': true,
    'stroke-width': (labelsConfig['stroke-width'] || 3) * 2,
  };
};
const getPointHaloStyleDefinition = (haloColor, hasExternalGraphic, size) => {
  return {
    'circle-color': haloColor,
    'circle-radius': hasExternalGraphic ? size * 15 : size * 3,
  };
};
const getLineHaloStyleDefinition = (haloColor, width) => {
  return {
    'line-color': haloColor,
    'line-width': width * 5,
  };
};
// returns array of styles for a given feature containing red/yellow feature style + halo effect
const getHighlitedStyleDefinition = (type, style, highlitedType, labelsVisible, selectedColors, zoom) => {
  const { minzoom, maxzoom } = style;
  if (minzoom && minzoom > zoom) {
    return [];
  } else if (maxzoom && maxzoom < zoom) {
    return [];
  }
  const {
    erroredColor: customErroredColor,
    highlitedColor: customHighlitedColor,
    selectedColor: customSelectedColor,
    haloColorHighlited: customHaloColorHighlited,
    haloColorSelected: customHaloColorSelected,
    labelAttributesName: customLabelAttributesName,
    labelCustomText: customLabelText,
  } = selectedColors;
  let color = '';
  if (highlitedType === 'highlited') {
    color = customHighlitedColor || highlitedColor;
  } else if (highlitedType === 'selected') {
    color = customSelectedColor || selectedColor;
  } else {
    color = customErroredColor || erroredColor;
  }
  let haloColor = '';
  if (highlitedType === 'highlited') {
    haloColor = customHaloColorHighlited || haloColorHighlited;
  } else if (highlitedType === 'selected') {
    haloColor = customHaloColorSelected || haloColorSelected;
  }
  let labels = '';
  if (customLabelAttributesName || customLabelText) {
    labels = {
      ...(customLabelText ? { text: customLabelText } : { attributes: customLabelAttributesName }),
      'font-color': '#D40000',
      'font-size': 14,
      'font-weight': 'bold',
      'offset-x': 0,
      'offset-y': -15,
      'stroke-color': '#FFFFFF',
      'stroke-visible': true,
      'stroke-width': 3,
    };
  } else if (highlitedType !== 'errored' && labelsVisible && style.labels?.attributes?.length > 0) {
    labels = getHaloLabelDefinition(style.labels, color);
  } else {
    labels = style.labels;
  }
  if (type === 'point' || type === 'multipoint') {
    const hasExternalGraphic = !!style.externalGraphic;
    const size = hasExternalGraphic ? style['icon-size'] : style['circle-radius'];
    return [
      ...(haloColor ? [getPointHaloStyleDefinition(haloColor, hasExternalGraphic, size)] : []),
      {
        ...style,
        ...(highlitedType === 'errored' ? {} : { 'circle-color': color }),
        ...(hasExternalGraphic
          ? { 'icon-size': highlitedType === 'errored' ? size : size * 1.5 }
          : { 'circle-radius': highlitedType === 'errored' ? size : size * 2 }),
        labels,
      },
      ...(highlitedType === 'errored'
        ? [
            {
              externalGraphic: 'excluded_red.svg',
              'icon-size': getErroredIconScale(size, hasExternalGraphic, zoom),
              'fill-opacity': style['fill-opacity'],
              minzoom: style.minzoom,
              maxzoom: style.maxzoom,
            },
          ]
        : []),
    ];
  } else if (type === 'linestring' || type === 'multilinestring') {
    return [
      ...(haloColor ? [getLineHaloStyleDefinition(haloColor, style['line-width'])] : []),
      { ...style, ...(highlitedType === 'errored' ? { 'line-dash': [10, 10] } : {}), 'line-color': color, labels },
    ];
  }
  return [
    {
      ...style,
      ...(highlitedType === 'errored' ? { 'line-dash': [10, 10] } : {}),
      ...(highlitedType === 'errored' ? {} : { 'line-width': style['line-width'] * 5 }),
      ...(haloColor ? { 'fill-outline-color': haloColor } : {}),
      'fill-color': color,
      labels,
    },
  ];
};
const getStyleDefinition = (style, { value, feature } = {}) => {
  if (style.uniques) {
    const uniqueValue = value || feature.get(style.uniques.property);
    if (!uniqueValue && uniqueValue !== false) {
      return style.uniques.values.null || style;
    }
    return style.uniques.values[uniqueValue] || style;
  } else if (style.ranges) {
    const uniqueValue = value || feature.get(style.ranges.property);
    if (!uniqueValue && uniqueValue !== 0) {
      return style;
    }
    const rangeStyle = style.ranges.values.find(
      value => uniqueValue >= value['min-value'] && uniqueValue <= value['max-value']
    );
    return rangeStyle || style;
  }
  return style;
};

const getFeatureStyle = ({
  feature,
  style,
  type,
  labelsVisible,
  layer,
  iconBitmaps,
  directionArrowsVisible,
  styleParameters,
  selectedColors = {},
  cacheLayer = false,
  idPropertyName,
  zoom,
  isDirectionArrowsVisible,
  highlitedFeatures,
  selectedFeatures,
  erroredFeatures,
  measuredFeatures,
  cacheFilterFeatures,
}) => {
  const styleDefinition = getStyleDefinition(style, {
    feature,
    value: styleParameters?.value,
    parameter: styleParameters?.parameter,
  });
  if (Object.prototype.hasOwnProperty.call(styleDefinition, 'visible') && !styleDefinition.visible) return null;
  const featureId = feature.get(idPropertyName) || feature.getId();
  const highlitedType = isFeatureHighlitedOrSelected(
    featureId,
    layer,
    highlitedFeatures,
    selectedFeatures,
    erroredFeatures
  );
  const hasFeatureMeasure = getFeatureMeasureGeometry(featureId, layer, measuredFeatures) ? true : false;
  const directionArrowsEnabled = isDirectionArrowsVisible ? directionArrowsVisible : false;
  const includesInCacheFilter = cacheLayer
    ? isFeatureIncludesInCacheFilter(featureId, layer, cacheFilterFeatures)
    : true;
  if (!includesInCacheFilter) {
    return null;
  } else if (highlitedType) {
    const highlitedLabelsVisible = labelsVisible || selectedColors.labelsVisible;
    const styles = getHighlitedStyleDefinition(
      type,
      styleDefinition,
      highlitedType,
      highlitedLabelsVisible,
      selectedColors,
      zoom
    );
    return getHighlitedStyles({
      layer,
      feature,
      featureId,
      styles,
      type,
      labelsVisible: highlitedLabelsVisible,
      directionArrowsVisible: directionArrowsEnabled,
      hasFeatureMeasure,
      zoom,
      iconBitmaps,
    });
  }
  return getStyle(feature, styleDefinition, type, labelsVisible, directionArrowsEnabled, zoom, iconBitmaps);
};

const getHighlitedStyles = ({
  layer,
  feature,
  featureId,
  styles,
  type,
  labelsVisible,
  directionArrowsVisible,
  hasFeatureMeasure,
  zoom,
  iconBitmaps,
}) => {
  if (hasFeatureMeasure) {
    const featureMeasuremGeom = getFeatureMeasureGeometry(featureId, layer);
    const featureMeasureGeometry = new GeoJSON().readGeometry(featureMeasuremGeom, {
      featureProjection: defaultEpsg || 'EPSG:3857',
      dataProjection: defaultEpsg || 'EPSG:3857',
    });
    const geomTypeStyleFunction = typeStyleFunctionsDist[featureMeasureGeometry?.getType()];
    return [
      ...styles
        .map(style => {
          return getStyle(feature, style, type, labelsVisible, directionArrowsVisible, zoom, iconBitmaps);
        })
        .flat(),
      ...(hasFeatureMeasure && geomTypeStyleFunction
        ? this[geomTypeStyleFunction]({ geometry: featureMeasureGeometry, simple: true })
        : []),
    ];
  }
  return styles
    .map(style => {
      return getStyle(feature, style, type, labelsVisible, directionArrowsVisible, zoom, iconBitmaps);
    })
    .flat();
};
const getStyle = (feature, style, type, labelsVisible, directionArrowsVisible, zoom, iconBitmaps) => {
  let labelsText = false;
  let labelsStyle;
  if (style.minzoom && style.minzoom > zoom) {
    return;
  } else if (style.maxzoom && style.maxzoom < zoom) {
    return;
  } else if (style.labels?.minzoom && style.labels.minzoom > zoom) {
    labelsText = false;
  } else if (style.labels?.maxzoom && style.labels.maxzoom < zoom) {
    labelsText = false;
  } else {
    labelsText = labelsVisible && getLabels(feature, style.labels);
    labelsStyle = { ...style.labels };
  }
  if (type === 'point' || type === 'multipoint') {
    const {
      externalGraphic,
      'icon-size': iconSize,
      'circle-radius': radius,
      'circle-color': circleColor,
      'fill-opacity': fillOpacity,
      'fill-outline-color': fillOutlineColor,
      'fill-outline-opacity': fillOutlineOpacity,
      'fill-outline-width': fillOutlineWidth,
    } = style;
    const iconSizeScale = getIconScale(iconSize, zoom);
    return getPointStyle({
      externalGraphic,
      iconSize: iconSizeScale,
      radius,
      circleColor,
      fillOpacity,
      fillOutlineColor,
      fillOutlineOpacity,
      fillOutlineWidth,
      labels: labelsText,
      labelsStyle,
      iconBitmaps,
    });
  } else if (type === 'linestring' || type === 'multilinestring') {
    const {
      'line-width': lineWidth,
      'line-color': lineColor,
      'line-dash': lineDash,
      'fill-opacity': fillOutlineOpacity,
    } = style;
    return [
      getLineStyle({
        lineColor,
        fillOutlineOpacity,
        lineWidth,
        lineDash,
        labels: labelsText,
        labelsStyle,
      }),
      ...(directionArrowsVisible && getLineDirectionArrow(feature, lineColor, lineWidth, fillOutlineOpacity, zoom)
        ? [getLineDirectionArrow(feature, lineColor, lineWidth, fillOutlineOpacity, zoom)]
        : []),
    ];
  } else if (type === 'polygon' || type === 'multipolygon') {
    const {
      'fill-color': fillColor,
      'fill-opacity': fillOpacity,
      'line-width': lineWidth,
      'line-dash': lineDash,
      'fill-outline-opacity': fillOutlineOpacity,
      'fill-outline-color': fillOutlineColor,
      externalGraphic,
      'icon-size': iconSize = 1,
    } = style;
    const iconSizeScale = getIconScale(iconSize, zoom);
    return getPolygonStyle({
      fillColor,
      fillOpacity,
      fillOutlineColor,
      fillOutlineOpacity,
      lineWidth,
      lineDash,
      labels: labelsText,
      labelsStyle,
      externalGraphic,
      iconSize: iconSizeScale,
    });
  }
};
const getPointStyle = memoizer(
  ({
    externalGraphic,
    iconSize,
    radius,
    circleColor,
    fillOpacity,
    fillOutlineColor,
    fillOutlineOpacity,
    fillOutlineWidth,
    labels,
    labelsStyle,
    iconBitmaps,
  }) => {
    return new Style({
      image: externalGraphic
        ? getIcon({
            iconBitmaps,
            externalGraphic,
            iconSize,
            opacity: fillOpacity,
          })
        : getCircle({
            circleColor,
            fillOpacity,
            radius,
            fillOutlineColor,
            fillOutlineOpacity,
            fillOutlineWidth,
          }),
      text: getText({ text: labels, labelsStyle }),
    });
  }
);
const getLineStyle = memoizer(({ lineColor, fillOutlineOpacity, lineWidth, lineDash, labels, labelsStyle }) => {
  return new Style({
    stroke: getStroke({
      fill: lineColor,
      opacity: fillOutlineOpacity,
      width: lineWidth,
      dash: lineDash,
    }),
    text: getText({
      text: labels,
      labelsStyle,
      textPlacement: 'line',
    }),
  });
});
const getPolygonStyle = memoizer(
  ({
    fillColor,
    fillOpacity,
    fillOutlineColor,
    fillOutlineOpacity,
    lineWidth,
    lineDash,
    labels,
    labelsStyle,
    externalGraphic,
    iconSize,
  }) => {
    return new Style({
      fill: externalGraphic
        ? getFillPattern({ externalGraphic, opacity: fillOpacity, iconSize })
        : getFill({
            color: fillColor,
            opacity: fillOpacity,
          }),
      stroke: getStroke({
        fill: fillOutlineColor,
        opacity: fillOutlineOpacity,
        width: lineWidth,
        dash: lineDash,
      }),
      text: getText({ text: labels, labelsStyle }),
    });
  }
);
const getIcon = memoizer(({ externalGraphic, iconSize = 1, opacity = 1, iconBitmaps }) => {
  if (iconBitmaps && !iconBitmaps[externalGraphic]) throw new Error(`Image ${externalGraphic} not loaded`);
  return new Icon({
    ...(iconBitmaps[externalGraphic]
      ? { img: iconBitmaps[externalGraphic] }
      : { src: `${import.meta.env.VUE_APP_API_URL}/icons/${externalGraphic}` }),
    opacity,
    scale: iconSize,
  });
});
const getFillPattern = memoizer(({ externalGraphic, opacity, iconSize }) => {
  return new FillPattern({
    opacity,
    image: getIcon({ externalGraphic }),
    scale: iconSize,
  });
});
const getCircle = memoizer(
  ({ circleColor, fillOpacity, radius, fillOutlineColor, fillOutlineOpacity, fillOutlineWidth }) => {
    const hasOutline = fillOutlineColor && fillOutlineOpacity && fillOutlineWidth;
    return hasOutline
      ? new Circle({
          fill: getFill({
            color: circleColor,
            opacity: fillOpacity,
          }),
          stroke: getStroke({
            fill: fillOutlineColor || '#ffffff',
            opacity: fillOutlineOpacity || 1,
            width: fillOutlineWidth || 1,
          }),
          radius,
        })
      : new Circle({
          fill: getFill({
            color: circleColor,
            opacity: fillOpacity,
          }),
          radius,
        });
  }
);
const getFill = memoizer(({ color, opacity }) => {
  return new Fill({
    color: hexToRgba(color, opacity),
  });
});
const getStroke = memoizer(({ fill, opacity, width, dash }) => {
  return new Stroke({
    color: hexToRgba(fill, opacity),
    width,
    lineDash: dash,
  });
});
const getText = memoizer(({ text, labelsStyle, color = '#202124', textPlacement = 'point' }) => {
  if (!text || !labelsStyle) {
    return;
  }
  // Fix obsługi dwóch struktur etykiet na raz
  // TODO: Usunąć go
  if (Array.isArray(labelsStyle)) {
    return new Text({
      text,
      fill: getFill({
        color,
        opacity: 1,
      }),
    });
  } else {
    let {
      'font-color': fontColor,
      'font-size': fontSize,
      'font-weight': fontWeight,
      'stroke-visible': strokeVisible,
      'stroke-color': strokeColor,
      'stroke-width': strokeWidth,
      'offset-x': offsetX,
      'offset-y': offsetY,
      attributes_advanced: { justify = 'center', text: advancedText = '' } = {},
    } = labelsStyle;

    fontColor = typeof fontColor !== 'undefined' ? fontColor : '#000000';
    fontSize = typeof fontSize !== 'undefined' ? fontSize : 12;
    fontWeight = typeof fontWeight !== 'undefined' ? fontWeight : 'normal';
    strokeVisible = typeof strokeVisible !== 'undefined' ? strokeVisible : false;
    strokeColor = typeof strokeColor !== 'undefined' ? strokeColor : '#FFFFFF';
    strokeWidth = typeof strokeWidth !== 'undefined' ? strokeWidth : 3;
    offsetX = typeof offsetX !== 'undefined' ? offsetX : 0;
    offsetY = typeof offsetY !== 'undefined' ? offsetY : 0;

    const font = `${fontWeight} ${fontSize}px sans-serif`;
    return new Text({
      font,
      text,
      justify,
      fill: getFill({
        color: fontColor,
        opacity: 1,
      }),
      placement: textPlacement,
      ...(advancedText ? { maxAngle: 0 } : { maxAngle: Math.PI / 16 }),
      stroke: strokeVisible
        ? getStroke({
            fill: strokeColor,
            opacity: 1,
            width: strokeWidth,
            dash: [],
          })
        : '',
      offsetX,
      offsetY,
    });
  }
});
const getLabels = (feature, labels) => {
  if (!labels) {
    return '';
  }
  let label = '';
  if (labels.text) {
    label = labels.text;
  } else if (labels.attributes_advanced?.text) {
    label = getQueryValue(labels.attributes_advanced.text, feature, {
      isFeature: true,
    });
  } else {
    // Fix obsługi dwóch struktur etykiet na raz
    // TODO: Usunąć go
    const labelsArray = Array.isArray(labels) ? labels : labels.attributes || [];
    label = labelsArray.reduce((total, label) => {
      let labelValue = feature.get(label);
      if (labelValue === false) {
        labelValue = labelValue.toString();
      }
      return `${total} ${labelValue || labelValue == 0 ? labelValue : ''}`;
    }, '');
  }
  return label;
};

const getLineDirectionArrow = (feature, lineColor, lineWidth, fillOutlineOpacity, zoom) => {
  const geometry = feature.getGeometry();
  if (!geometry || geometry.getType() !== 'LineString') return null;
  // if (geometry.getLength() / map.getView().getResolution() < 13) return null;
  // const zoom = map.getView().getZoomForResolution(map.getView().getResolution());
  if (zoom < 16) return null;
  const coords = geometry.getCoordinates();
  const scale = lineWidth / 10 + 0.3;
  const dx = coords[coords.length - 1][0] - coords[0][0];
  const dy = coords[coords.length - 1][1] - coords[0][1];
  const rotation = Math.atan2(dy, dx);
  const returnStyle = new Style({
    geometry: new Point(geometry.getFlatMidpoint()),
    image: new Icon({
      src: '/arrow.svg',
      anchor: [0.75, 0.5],
      color: lineColor,
      opacity: fillOutlineOpacity,
      rotateWithView: true,
      rotation: -rotation,
      scale,
    }),
  });
  return returnStyle;
};
const getMarkerStyle = memoizer(({ path = '/marker_red.svg' } = {}) => {
  return new Style({
    image: new Icon({
      anchor: [0.5, 46],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: path,
    }),
  });
});
const hexToRgba = (hex, opacity) => {
  const [r, g, b] = Array.from(asArray(hex));
  return asString([r, g, b, opacity]);
};
const getErroredIconScale = (size, isIcon = false, zoom) => {
  if (isIcon) {
    return size;
  } else {
    if (zoom >= 20) {
      return size / 10 + 0.25;
    } else if (zoom >= 15) {
      return size / 5 + 0.5;
    } else {
      return size / 2.5 + 0.75;
    }
  }
};
const getIconScale = (iconSize, zoom) => {
  if (zoom >= 20) {
    return iconSize * 0.5;
  } else if (zoom >= 15) {
    return iconSize * 0.25;
  } else {
    return iconSize * 0.125;
  }
};

export {
  erroredColor,
  highlitedColor,
  selectedColor,
  haloColorHighlited,
  haloColorSelected,
  typeStyleFunctionsDist,
  isFeatureIncludesInCacheFilter,
  isFeatureHighlitedOrSelected,
  getFeatureMeasureGeometry,
  getHaloLabelDefinition,
  getPointHaloStyleDefinition,
  getLineHaloStyleDefinition,
  getHighlitedStyleDefinition,
  getStyleDefinition,
  getFeatureStyle,
  getHighlitedStyles,
  getStyle,
  getPointStyle,
  getLineStyle,
  getPolygonStyle,
  getIcon,
  getFillPattern,
  getCircle,
  getFill,
  getStroke,
  getText,
  getLabels,
  getLineDirectionArrow,
  getMarkerStyle,
  hexToRgba,
  getErroredIconScale,
  getIconScale,
};

<template>
  <span class="gs-popover--wrapper">
    <slot
      name="activator"
      :onClick="onClickListeners"
      :onHover="onHoverListeners"
      :onHover_prevent="onReverseHoverListeners"
      :close="closePopover"
      :open="openPopover"
    ></slot>
    <Transition name="fade">
      <div v-if="isPopoverVisible" ref="gs-popover" class="gs-popover">
        <div ref="gs-popover--arrow" class="gs-popover--arrow gs-popover--arrow__visible"></div>
        <div ref="gs-popover--arrow__ghost" class="gs-popover--arrow"></div>
        <div class="gs-popover--content tw:rounded-default pa-4">
          <slot></slot>
        </div>
      </div>
    </Transition>
  </span>
</template>

<script>
import { autoUpdate, computePosition, flip, shift, offset, arrow } from '@floating-ui/dom';

let cleanupAutoupdate = () => {};

export default {
  name: 'PopoverMenu',
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isPopoverVisible: false,
  }),
  watch: {
    isPopoverVisible: {
      handler(isVisible) {
        if (isVisible) {
          this.$nextTick(() => this.updatePopover());
        } else {
          cleanupAutoupdate();
        }
      },
      immediate: true,
    },
  },
  computed: {
    onClickListeners() {
      return { click: () => this.togglePopover() };
    },
    onHoverListeners() {
      return {
        mouseenter: () => this.openPopover(),
        mouseleave: () => this.closePopover(),
      };
    },
    /** Especially useful when used as 'prevent' for child hovering */
    onReverseHoverListeners() {
      return {
        mouseenter: () => this.closePopover(),
        mouseleave: () => this.openPopover(),
      };
    },
  },
  methods: {
    togglePopover() {
      this.isPopoverVisible = !this.isPopoverVisible;
    },
    openPopover() {
      this.isPopoverVisible = true;
    },
    closePopover() {
      this.isPopoverVisible = false;
    },
    updatePopover() {
      const popoverElement = this.$refs['gs-popover'];
      const popoverArrowElement = this.$refs['gs-popover--arrow'];
      const popoverArrowShadowElement = this.$refs['gs-popover--arrow__ghost'];
      const triggerElement = this.$children[0].$el;
      cleanupAutoupdate = autoUpdate(triggerElement, popoverElement, () =>
        computePosition(triggerElement, popoverElement, {
          middleware: [offset(10), shift(), flip(), arrow({ element: popoverArrowElement })],
          strategy: 'fixed',
          placement: 'right-start',
          ...this.config,
        }).then(({ x, y, placement, middlewareData }) => {
          /** Popover placement */
          Object.assign(popoverElement.style, {
            left: `${x}px`,
            top: `${y}px`,
          });
          const placementSide = placement.split('-')[0];

          /** Arrow placement */
          const { x: arrowX, y: arrowY } = middlewareData.arrow;
          const staticSide = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right',
          }[placementSide];
          [popoverArrowElement, popoverArrowShadowElement].forEach(el =>
            Object.assign(el.style, {
              left: arrowX != null ? `${arrowX}px` : '',
              top: arrowY != null ? `${arrowY}px` : '',
              right: '',
              bottom: '',
              [staticSide]: '-4px',
            })
          );
        })
      );
    },
  },
  beforeDestroy() {
    cleanupAutoupdate();
  },
};
</script>

<style lang="scss" scoped>
$popoverShadow: 0 4px 12px -1px rgb(0 0 0 / 0.2);
$popoverBorder: 1px solid #ebebeb;

.gs-popover {
  width: max-content;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
}
.gs-popover--content {
  background: #fff;
  border: $popoverBorder;
  box-shadow: $popoverShadow;
}

.gs-popover--arrow {
  position: absolute;
  width: 11px;
  height: 11px;
  background: #fff;
  transform: rotate(45deg);
}

.gs-popover--arrow__visible {
  box-shadow: $popoverShadow;
  outline: $popoverBorder;
  z-index: -4;
}

/* Transition */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0 !important;
}

.fade-enter-to,
.fade-leave {
  opacity: 1 !important;
}
</style>

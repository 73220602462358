import { apiV2 as api } from '@/services/api';

const actions = {
  async getTile(store, payload) {
    const { layerId, envelope, features_filter, attributes, zxy } = payload;
    return await api.post(
      `/layers/${layerId}/mvt`,
      { features_filter, envelope, attributes, zxy, use_cache: true },
      { responseType: 'arraybuffer', skipDefaultErrorHandler: true }
    );
  },
};

export default {
  namespaced: true,
  actions,
};
